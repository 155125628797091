<template>
  <selected-filters
    :selectedList="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";

export default {
  components: { SelectedFilters },

  computed: {
    ...mapState({
      filterModel: (state) => state.equipmentsModule.filterModel,
      filter: (state) => state.equipmentsModule.filterData,
      equipmentsModule: (state) => state.equipmentsModule,
    }),

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: `<b>Search</b>: ${this.filterModel.search}`,
          resetArgument: "search",
        },
        {
          ifCondition: this.filterModel.price[1] > 0,
          boxText: `<b>Prices</b>: ${this.filterModel.price[0]} - ${this.filterModel.price[1]}`,
          resetArgument: "price",
        },
        {
          ifCondition: !!this.filterModel.type,
          boxText: `<b>Type</b>: ${this.getFilterDataNames("type")}`,
          resetArgument: "type",
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getEquipmentsList: "equipmentsModule/getEquipmentsList",
    }),
    ...mapMutations({
      filterEquipments: "equipmentsModule/filterEquipments",
    }),

    getFilterDataNames(type) {
      if (type === "type" && this.filterModel.type) {
        return this.equipmentsModule.types.filter(
          (x) => x.value === this.filterModel.type
        )[0].name;
      }
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterEquipments();
      await this.getEquipmentsList(this.filter);
    },
  },
};
</script>
