<template>
  <div class="options">
    <div
      v-if="!colorsList.length || isAddColor"
      :class="{ 'shadowed-blok': isAddColor }"
      class="position-relative"
    >
      <span
        v-if="isAddColor"
        @click="isAddColor = false;"
        class="mdi mdi-close position-absolute options__close-icon"
      ></span>

      <title-component :title-text="topHeaderTitle" />

      <hr v-if="isAddColor" class="mb-2 mt-1" />

      <div class="d-flex flex-wrap">
        <div class="P-padding-50 position-relative">
          <ott-input
            v-model="colorName"
            :error="$v.options.colorName.$error"
            label="Color Name*"
          />

          <div
            @click.stop="openColorPicker = !openColorPicker"
            :style="{ backgroundColor: colorCode?.hexa }"
            class="options__color-icon d-flex cursor-pointer"
          >
            <img
              v-if="!colorCode"
              src="@/assets/images/empty.png"
              width="100"
            />
          </div>

          <transition name="fade">
            <div v-if="openColorPicker" class="options__color-picker">
              <v-color-picker
                v-model="colorCode"
                elevation="10"
                dot-size="15"
                mode="hexa"
                show-swatches
                swatches-max-height="120px"
              />
            </div>
          </transition>
        </div>

        <div class="P-padding-50">
          <ott-input
            v-model.number="colorVariantStock"
            :min="1"
            :error="$v.options.colorVariantStock.$error"
            type="number"
            label="Variant Stock*"
          />
        </div>
      </div>
      
      <div class="ms-2">
        <ott-checkbox label="Default" v-model="isDefaultColor" />
      </div>

      <div v-if="isEditMode || isAddColor" class="d-flex justify-end">
        <ott-button-loader
          button-text="Add"
          class-name="primary-sm-btn"
          @onClick="addColor"
        />
      </div>
    </div>

    <div v-if="colorsList.length" class="mt-3">
      <div class="mb-3">
        <title-component
          title-text="Color"
          :isAdd="isAddTitle"
          :click="() => (isAddColor = true)"
        />
      </div>

      <div class="G-scroll-list">
        <table class="colors-list">
          <tr>
            <th
              v-for="(item, key) of tableHeader"
              :key="key"
              class="secondary--text w-33"
            >
              {{ item }}
            </th>
          </tr>
          <template v-for="(item, index) of colorsList">
            <tr
              :key="index"
              class="P-hovered-tr"
            >
              <td class="secondary--text">
                <v-radio-group v-model="selectedColor" @change="selectColor()">
                  <ott-radio :value="item" :label="item.colorName" />
                </v-radio-group>
              </td>
              <td class="position-relative">
                <div
                  @click.stop="itemColorClick(item.showColorPicker, item.colorCode)"
                  :style="{ backgroundColor: item.colorCode }"
                  class="options__color-icon-table d-flex align-center justify-center mx-auto cursor-pointer"
                >
                  <img
                    v-if="!item.colorCode"
                    src="@/assets/images/empty.png"
                    width="100"
                  />
                </div>

                <transition name="fade">
                  <div v-if="item.showColorPicker" class="options__color-picker-table">
                    <v-color-picker
                      v-model="item.colorCode"
                      elevation="10"
                      dot-size="15"
                      mode="hexa"
                      show-swatches
                      swatches-max-height="120px"
                    />
                  </div>
                </transition>
              </td>
              <td class="posoition-relative">
                <div
                  :class="
                    !item.showItemStock
                      ? 'justify-center'
                      : 'justify-space-between'
                  "
                  class="d-flex align-center cursor-pointer"
                >
                  <div 
                    v-if="!item.showItemStock"
                    @click="setColorListItemKey({
                      colorCode: item.colorCode,
                      key: 'showItemStock',
                      value: true,
                    })"
                  >{{ item.inStock }}</div>
                  <ott-input
                    v-if="item.showItemStock"
                    v-model.number="item.inStock"
                    :min="0"
                    @onChange="colorItemInStockChange(item.colorCode, item.inStock)"
                    class="w-92"
                  />
                  <span
                    @click="openWarningModal(item.colorCode, index)"
                    class="colors-list__remove-icon remove mdi mdi-window-close red--text position-absolute cursor-pointer"
                  />
                </div>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>

    <WarningModal
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :isDangerButton="true"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false"
      @btnFunction="deleteColorFunc()"
      btnText="Delete"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import TitleComponent from "@/components/TitleComponent";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import WarningModal from "@/components/WarningModal";

export default {
  name: "Optoins",
  components: {
    TitleComponent,
    OttInput,
    OttRadio,
    OttCheckbox,
    OttButtonLoader,
    WarningModal,
  },
  validations: {
    options: {
      colorName: {
        required,
      },
      colorVariantStock: {
        required,
      },
    },
  },
  data() {
    return {
      openColorPicker: false,
      tableHeader: ["Color Name", "Color", "Stock"],
      showWarningModal: false,
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      removedColorCode: null,
      removedColorIndex: null
    };
  },

  watch: {
    isAddColor() {
      this.$v.options.$reset()
      this.resetVariables()
    },
  },
  
  computed: {
    ...mapState({
      options: (state) => state.equipmentsModule.equipmentData.options,
      isEditMode: (state) => state.equipmentsModule.isEditMode,
      selectedEquipmentId: (state) => state.equipmentsModule.selectedEquipmentId,
    }),

    warningModalHeading() {
      return `Delete Equipment Color?`
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>delete</ins></span> equipment color.<br> If you're not sure, you can cancel this operation.`
    },

    colorsList() {
      return this.options.colorsList;
    },

    topHeaderTitle() {
      if (this.isAddColor) {
        return "Add Color";
      } else {
        return "Color";
      }
    },

    isAddTitle() {
      if (!this.isAddColor && this.colorsList.length) {
        return true;
      } else {
        return false;
      }
    },

    selectedColor: {
      get() {
        return this.options.selectedColor;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "options",
          secondLevel: "selectedColor",
          value,
        });
      },
    },

    colorName: {
      get() {
        return this.options.colorName;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "options",
          secondLevel: "colorName",
          value,
        });
      },
    },

    colorCode: {
      get() {
        return this.options.colorCode;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "options",
          secondLevel: "colorCode",
          value,
        });
      },
    },

    colorVariantStock: {
      get() {
        return this.options.colorVariantStock;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "options",
          secondLevel: "colorVariantStock",
          value,
        });
      },
    },

    isDefaultColor: {
      get() {
        return this.options.isDefaultColor;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "options",
          secondLevel: "isDefaultColor",
          value,
        });
      },
    },

    isAddColor: {
      get() {
        return this.options.isAddColor;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "options",
          secondLevel: "isAddColor",
          value,
        });
      },
    },
  },

  mounted() {
    // check if there is default color, select that color for first time showing
    this.getAndSelectDefaultColor()
  },

  methods: {
    ...mapActions({
      getEquipmentData: "equipmentsModule/getEquipmentData",
      updateEquipment: "equipmentsModule/updateEquipment"
    }),

    ...mapMutations({
      setEquipmentData: "equipmentsModule/setEquipmentData",
      setColorListItemKey: "equipmentsModule/setColorListItemKey",
      addNewColor: "equipmentsModule/addNewColor",
      spliceColorFromList: "equipmentsModule/spliceColorFromList"
    }),

    async itemColorClick(value, colorCode) {
      this.colorsList.map((item, index) => {
        this.setColorListItemKey({
          colorCode: item.colorCode,
          key: "showColorPicker",
          value: false,
        });
      });

      this.setColorListItemKey({
        colorCode,
        key: "showColorPicker",
        value: !value,
      });

      if (value) { // after close color picker, call update function
        await this.updateEquipment({ 
          options: { colorsList: this.colorsList }
        })
      }
    },

    colorItemInStockChange(colorCode, inStock) {
      this.setColorListItemKey({
        colorCode,
        key: "showItemStock",
        value: false,
      });
    },

    openWarningModal(colorCode, index) {
      this.removedColorCode = colorCode
      this.removedColorIndex = index
      this.showWarningModal = true
    },

    async deleteColorFunc() {
      this.spliceColorFromList(this.removedColorIndex)
      this.warningModalIsLoading = true

      await this.updateEquipment({
        options: {
          colorsList: this.colorsList
        }
      }).finally(() => this.warningModalIsLoading = false)

      this.showWarningModal = false;
    },

    async addColor() {
      this.$v.options.$touch();

      if (!this.$v.options.$error) {

        /* 
          if added color is default change previus default color, 
          and set that color as selected color
        */
        if (this.isDefaultColor) {
          const colorsList = Object.assign([], this.colorsList)
          colorsList.map(item => item.isDefaultColor = false)

          // update colorsList
          this.setEquipmentData({
            firstLevel: "options",
            secondLevel: "colorsList",
            value: colorsList,
          });

          // update selectedColor
          this.setEquipmentData({
            firstLevel: "options",
            secondLevel: "selectedColor",
            value: {
              colorName: this.colorName,
              colorCode: this.colorCode?.hexa,
              inStock: this.colorVariantStock,
              showColorPicker: false, // for front-end
              showItemStock: false,  // for front-end
              isDefaultColor: this.isDefaultColor
            },
          });
        }

        this.addNewColor({
          colorName: this.colorName,
          colorCode: this.colorCode?.hexa,
          inStock: this.colorVariantStock,
          showColorPicker: false, // for front-end
          showItemStock: false,  // for front-end
          isDefaultColor: this.isDefaultColor
        })

        await this.updateEquipment({
          options: { 
            colorsList: this.colorsList,
            selectedColor: this.selectedColor
          },
        })

        await this.getEquipmentData(this.selectedEquipmentId)

        this.isAddColor = false;
        this.resetVariables()
      }
    },

    async selectColor() {
      const colorsList = Object.assign([], this.colorsList)

      // Change default color for showing selected color
      colorsList.map(item => item.isDefaultColor = false)

      await this.updateEquipment({
        options: {
          colorsList,
          selectedColor: this.selectedColor
        }
      })
    },

    async getAndSelectDefaultColor() {
      let defaultColor = null
      if (this.colorsList.length) {
        this.colorsList.map(item => {
          if (item.isDefaultColor) {
            defaultColor = item
          }
        })
      }

      if (defaultColor) {
        await this.updateEquipment({
          options: {
            selectedColor: defaultColor
          }
        })
      }
    },

    resetVariables() {
      this.colorName = null;
      this.colorCode = null;
      this.colorVariantStock = null;
      this.isDefaultColor = false;
      this.openColorPicker = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.options {
  &__close-icon {
    color: $neutral-color;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &__color-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 14px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid $neutral-color;
  }

  &__color-picker {
    position: absolute;
    z-index: 9;
    right: 3%;
  }

  &__color-picker-table {
    position: absolute;
    z-index: 9;
    right: -11%;
    top: 45px;
  }

  &__color-icon-table {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid $neutral-color;

    & img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.colors-list {
  &__remove-icon {
    display: none;
    right: 8px;
  }
}

hr {
  border: unset;
  border-top: 1px solid rgba(165, 170, 198, 0.45);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

table {
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  tr,
  td,
  th {
    border: 1px solid $neutral-color45;
    text-align: center;
  }

  & tr {
    th {
      font-size: $txt14;
      font-weight: bold;
      padding: 8px;
      vertical-align: middle;
      text-transform: capitalize;
    }

    td {
      font-size: $txt14;
      word-break: break-all;
      padding: 8px;
      transition: $transition;
      vertical-align: middle;
      text-transform: capitalize;

      p {
        margin-bottom: 0;
      }
    }

    &:hover {
      .P-actions {
        opacity: 1;
        visibility: visible;
      }
      td {
        background-color: $neutral-color7;
      }
    }
  }
}

.P-hovered-tr {
  position: relative;

  &:after {
    width: 2px;
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    transition: $transition;
    background-color: $primary-color;
    content: "";
  }

  &:hover {
    &:after {
      height: 100%;
    }

    & td {
      background-color: $neutral-color7;
    }

    & .remove {
      display: block;
    }
  }
}

.theme--dark {
  & th {
    background-color: $dark_light;
  }

  .P-hovered-tr {
    &:hover {
      & td {
        background-color: transparent !important;
      }
    }
  }
}

.w-33 {
  width: 33.333%;
}

.border-0 {
  border: unset;
}

.shadowed-blok {
  box-shadow: 0 0 5px #284e6626;
  border-radius: 3px;
  padding: 10px 20px 20px 20px;
}

.w-92 {
  width: 92%;
}
</style>