<template>
  <div class="discounts-tab">
    <div v-if="discountsList.length" class="P-table-list P-table-body">
      <table>
        <tr>
          <th
            v-for="(header, index) of tableHeaders"
            :key="index"
            :class="{ 'text-start': index === 0 }"
            class="secondary--text"
          >
            <ott-checkbox
              v-if="index === 0"
              v-model="selectedAllDiscounts"
              @input="selectAll($event)"
              :label="header"
            />
            <span v-else>{{ header }}</span>
          </th>
        </tr>

        <tr
          v-for="(item, index) of discountsList"
          :key="index"
          class="P-hovered-tr"
        >
          <td class="secondary--text text-start">
            <div class="w-max-cont ott-checkbox-cont">
              <v-checkbox
                v-model="selectedDiscountsList"
                :value="item.id"
                :label="item.generalInfo.name"
              />
            </div>
          </td>
          <td class="secondary--text">
            {{
              item.generalInfo.startDate && item.generalInfo.endDate
                ? item.generalInfo.startDate + " - " + item.generalInfo.endDate
                : "-"
            }}
          </td>
          <td
            v-html="getPriceGroupsNames(item.priceGroups)"
            class="secondary--text"
          />
          <td class="secondary--text">
            {{ item.generalInfo.defaultSalePercent }}%
          </td>
        </tr>
      </table>
    </div>

    <div
      v-if="discountsList.length"
      class="mt-4"
      :class="isEditMode ? 'G-justify-between' : 'G-justify-end'"
    >
      <ott-button-loader
        v-if="isEditMode"
        button-text="Save"
        class-name="primary-sm-btn"
        @onClick="saveEquipmentDiscount()"
      />

      <v-btn
        @click="openWarningModal()"
        class="txt-capitalize"
        tile
        color="normal"
      >
        <v-icon left> mdi-trash-can-outline </v-icon>
        Delete
      </v-btn>
    </div>

    <v-skeleton-loader
      v-if="!discountsList.length && showTableLoader"
      type="table-tbody"
    />

    <Transition name="fade">
      <v-alert
        v-if="!discountsList.length"
        border="top"
        class="mt-4"
        colored-border
        type="error"
        elevation="2"
      >
        There is not exist any discount for equipments...
      </v-alert>
    </Transition>

    <WarningModal
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalText="warningModalText"
      :isDangerButton="true"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false"
      @btnFunction="deleteDiscounts()"
      modalHeading="Delete Discount(s) ?"
      btnText="Delete Discounts"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttButton from "@/components/vuetifyComponents/OttButton";
import WarningModal from "@/components/WarningModal";

export default {
  name: "Discount",
  components: {
    OttCheckbox,
    OttButtonLoader,
    OttButton,
    WarningModal,
  },
  computed: {
    ...mapState({
      selectedEquipmentId: (state) =>
        state.equipmentsModule.selectedEquipmentId,
      discountTab: (state) => state.equipmentsModule.equipmentData.discount,
      isEditMode: (state) => state.equipmentsModule.isEditMode,
      discountsList: (state) => state.discountModule.dataTable,
      priceGroupAllList: (state) => state.priceGroupAdmin.priceGroupAllList,
      equipmentsList: (state) => state.equipmentsModule.dataTable,
    }),

    selectedDiscounts() {
      return this.discountTab.selectedDiscounts;
    },
  },

  watch: {
    selectedDiscountsList: {
      handler(newList) {
        this.setEquipmentData({
          firstLevel: "discount",
          secondLevel: "selectedDiscounts",
          value: newList,
        });

        if (newList.length) {
          this.selectedAllDiscounts = true;
        } else {
          this.selectedAllDiscounts = false;
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      selectedDiscountsList: [],
      tableHeaders: ["Name", "Date", "Price Group", "Percent"],
      accessShowingError: false,
      showTableLoader: false,
      selectedAllDiscounts: false,
      showWarningModal: false,
      warningModalText: `You're about to <span class='text-lowercase'><ins>delete</ins></span> 
      one or more Discounts! <br /> If you're not sure, you can cancel this operation.`,
      warningModalIsLoading: false,
    };
  },

  async created() {
    await this.getPriceGroupList();
    await this.getEquipmentsList({ limit: 10000 });
    await this.getDiscountsList();
  },

  mounted() {
    setTimeout(() => {
      this.accessShowingError = true;
    }, 300);

    this.selectedDiscountsList = Object.assign([], this.selectedDiscounts);
  },

  methods: {
    ...mapActions({
      getDiscountList: "discountModule/getDiscountList",
      deleteDiscount: "discountModule/deleteDiscount",
      getPriceGroupByTypeList: "priceGroupAdmin/getPriceGroupByTypeList",
      getEquipmentsList: "equipmentsModule/getEquipmentsList",
      updateEquipment: "equipmentsModule/updateEquipment",
    }),

    ...mapMutations({
      generateAllPriceGroups: "priceGroupAdmin/generateAllPriceGroups",
      setEquipmentData: "equipmentsModule/setEquipmentData",
    }),

    async getDiscountsList() {
      this.showTableLoader = true;
      await this.getDiscountList({
        limit: 10000,
        type: 2,
      }).finally(() => (this.showTableLoader = false));
    },

    async getPriceGroupList() {
      await Promise.all([
        this.getPriceGroupByTypeList({ type: 1 }),
        this.getPriceGroupByTypeList({ type: 2 }),
      ]).then((data) => {
        this.generateAllPriceGroups(data);
      });
    },

    getPriceGroupsNames(data) {
      if (data) {
        let pricesArray = [];

        data.map((price) => {
          pricesArray.push(price?.item);
        });

        let priceGroups = "";
        let list = this.priceGroupAllList.filter((x) =>
          pricesArray.includes(x.value)
        );
        let defaultPriceExists = pricesArray.includes(undefined);

        if (list.length) {
          list.forEach((item, index) => {
            priceGroups +=
              item.name[0].name + (index !== list.length - 1 ? ",<br /> " : "");
          });
        } else {
          priceGroups = "";
        }
        return priceGroups && defaultPriceExists
          ? priceGroups + ",<br />" + "Default"
          : priceGroups
          ? priceGroups
          : defaultPriceExists
          ? "Default"
          : "-";
      }
    },

    selectAll(value) {
      if (value) {
        this.discountsList.map((item) =>
          this.selectedDiscountsList.push(item.id)
        );
      } else {
        this.selectedDiscountsList = [];
      }
    },

    async saveEquipmentDiscount() {
      /* save selected dicsounts for equipment */
      if (this.selectedDiscountsList.length) {
        await this.updateEquipment({
          discount: { ...this.discountTab },
        });
      }
    },

    deleteDiscounts() {
      if (this.selectedDiscountsList.length) {
        this.warningModalIsLoading = true;

        this.deleteDiscount(this.selectedDiscountsList)
          .then(async () => {
            await this.getDiscountsList();
            this.selectAll(false); // unselect all selected discounts
          })
          .finally(() => {
            this.showWarningModal = false;
            this.warningModalIsLoading = false;
          });
      }
    },

    openWarningModal() {
      if (this.selectedDiscountsList.length) {
        this.showWarningModal = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/tableList";

.ott-checkbox-cont::v-deep {
  .v-input--selection-controls__ripple {
    display: none;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-label {
    font-size: $txt14;
    text-transform: capitalize !important;
  }

  .theme--light {
    &.v-label {
      color: $secondary-color;
    }

    &.v-icon {
      color: $neutral-color;
    }
  }

  .v-input--is-label-active {
    .v-input--selection-controls__input {
      &:before {
        opacity: 1 !important;
        visibility: visible !important;
      }
    }
  }

  .theme--dark {
    &.v-input--is-disabled {
      .v-input--selection-controls__input {
        &:before {
          content: "";
          position: absolute;
          width: 15px;
          height: 15px;
          background-color: transparent;
          left: 4px;
          top: 4px;
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .v-input--selection-controls__input {
      &:before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #ffffff;
        left: 6px;
        top: 6px;
        opacity: 0;
        visibility: hidden;
      }
    }

    &.v-label {
      color: $white;
    }

    .v-icon {
      color: $white;
    }

    .P-test {
      .v-icon {
        color: $primary-color !important;
      }
    }

    .primary--text {
      color: $primary-colorDM !important;
    }
  }

  .v-input--indeterminate {
    .v-icon {
      color: $primary-color !important;
    }
  }

  .v-input--indeterminate {
    .v-input--selection-controls__input {
      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.txt-capitalize {
  text-transform: capitalize;
}

.P-table-list {
  overflow: hidden;
  border-radius: 4px;
  margin-top: 12px;
  max-height: 274px;
  overflow-y: auto;
}
</style>