<template>
  <div class="P-filters">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>
    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.price"
        :min="0"
        :max="10000"
        prefix="$"
        @updateRange="filterData('price')"
        @clearData="resetData('price')"
        label="price"
      >
      </ott-range-select>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.type"
        :items="equipmentsModule.types"
        :persistentHint="true"
        :clear="true"
        selected-text="name"
        @emitFunc="filterData('type')"
        label="Type"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-select>
    </div>

    <selected-filters-list v-if="filterModel.filterCount.length" />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
import SelectedFiltersList from "./SelectedFiltersList"

export default {
  name: "FilterData",
  components: { OttSelect, OttCheckbox, OttInput, OttRangeSelect, SelectedFiltersList },

  data() {
    return {
      masks: {
        input: "MM-DD-YYYY",
      },
    };
  },

  computed: {
    ...mapState({
      filterModel: (state) => state.equipmentsModule.filterModel,
      equipmentsModule: (state) => state.equipmentsModule,
    }),

    minPrice() {
      let prices = [];
      this.equipmentsModule.dataTable.map((item) => {
        prices.push(item.price);
      });
      if (prices.length) {
        return Math.min(...prices);
      } else {
        return 0;
      }
    },
  },
  methods: {
    ...mapActions({
      getEquipmentsList: "equipmentsModule/getEquipmentsList",
    }),
    ...mapMutations({
      filterEquipments: "equipmentsModule/filterEquipments",
      setFilterPage: "equipmentsModule/setFilterPage",
      sortColumn: "equipmentsModule/sortColumn",
    }),

    /**
     * Get price group list
     * **/

    async filterData(type) {
      this.setFilterPage(1)
      this.filterEquipments();
      this.sortColumn();
      await this.getEquipmentsList(this.filter);
      if (
        (!this.filterModel.search && type === "search") ||
        (!this.filterModel.price[1] === 0 && type === "price") ||
        (!this.filterModel.type && type === "type")
      ) {
        this.filterModel.resetCurrentData(type);
      }
      this.filterModel.getFilterCount();
      this.$emit('resetSelectedList')
    },
    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterEquipments();
      this.sortColumn();
      await this.getEquipmentsList();
      this.$emit('resetSelectedList')
    },
  },
};
</script>
<style lang="scss" scoped>
.P-input-block {
  margin-bottom: 20px;

  &:first-child {
    margin-bottom: 10px;
  }
}

.P-ml-20 {
  margin-left: 20px;
}

.P-checkboxes {
  margin-bottom: 10px;
}
</style>
