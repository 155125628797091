<template>
  <ott-dialog
    v-if="equipments.modal.isOpen"
    :is-open="equipments.modal.isOpen"
    :show-footer="false"
    :modal-title="equipments.isEditMode ? 'Edit Equipments' : 'Add Equipments'"
    :width="1050"
    close-value="addProvider"
    @outside="cancel"
    @resetDataModal="cancel"
  >
    <template v-slot:modalBody>
      <div class="parent-add-provider-modal">
        <div class="left P-background-neutral7 G-flex-column G-justify-between">
          <div>
            <ul class="list">
              <li
                v-for="(item, index) in equipments.showList"
                @click="clickList(index)"
                :key="index"
                :class="{
                  listActive: index === equipments.activeTab,
                  'P-disable-tab': index !== 0 && !selectedEquipmentId
                }"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="main">
            <div :class="{'G-justify-between': equipments.activeTab === 4}">
              <title-component
                v-if="equipments.activeTab !== 1 && modalTitle"
                :title-text="modalTitle"
              />

              <img v-if="equipments.activeTab === 4" src="@/assets/images/percent.png" class="percent-icon"  />
            </div>
            <information ref="information" v-if="equipments.activeTab === 0" />
            <price ref="price" v-if="equipments.activeTab === 1" />
            <options ref="options" v-if="equipments.activeTab === 2" />
            <product-image ref="productImage" v-if="equipments.activeTab === 3" />
            <discount ref="discount" v-if="equipments.activeTab === 4" />

            <Transition name="fade">
              <v-alert
                v-if="isSaved"
                dense
                text
                class="mt-5"
                type="success"
                style="width: max-content"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
          <div v-if="!equipments.isEditMode" class="footer">
            <ott-button-loader
              :is-loading="isLoading"
              className="primary-sm-btn P-login-btn"
              :button-text="!equipments.activeTab ? 'Continue' : 'Save & Continue'"
              :icon-after="equipments.activeTab !== 1"
              @onClick="saveAndContinue"
            />
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";
import Information from "./tabs/Information";
import Options from "./tabs/Options";
import ProductImage from "./tabs/ProductImage";
import Price from "./tabs/Price";
import Discount from "./tabs/Discount";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "ManageEquipment",
  components: {
    TitleComponent,
    OttButton,
    OttDialog,
    OttButtonLoader,
    Information,
    Options,
    ProductImage,
    Price,
    Discount,
  },
  computed: {
    ...mapState({
      equipments: (state) => state.equipmentsModule,
      isSaved: (state) => state.appModule.isSaved,
      selectedEquipmentId: (state) => state.equipmentsModule.selectedEquipmentId,
      isLoading: (state) => state.appModule.isLoading
    }),

    optionsTab() {
      return this.equipments.equipmentData.options;
    },

    modalTitle() {
      let title = "";
      switch (this.equipments.activeTab) {
        case 0:
          title = "Information";
          break;
        case 1: 
          title = "Price";
          break;    
        case 3:
          title = "Product Image";
          break;
        case 4:
          title = "Discount List";
          break;
      }
      return title;
    },
  },

  created() {
    this.getCategoriesList(); // for showing in Information tab
  },
  methods: {
    ...mapMutations({
      setData: "equipmentsModule/setData",
      resetEquipmentData: "equipmentsModule/resetEquipmentData",
      toggleOpenModal: "equipmentsModule/toggleOpenModal",
    }),

    ...mapActions({
      getCategoriesList: "shippingClients/getCategoriesList",
    }),

    clickList(index) {
      if (index === 0 || this.selectedEquipmentId) {
        this.setData({ activeTab: index });
      }
    },

    async saveAndContinue() {
      switch (this.equipments.activeTab) {
        case 0: {
          // information tab
          this.$refs['information'].saveData()
          break
        }
        case 1: {
          if (this.selectedEquipmentId) {
            this.setData({ activeTab: 2 })
          }
          this.$refs.price.savePrices()
          break
        }
        case 2: {
          if (!this.$refs?.options?.colorsList.length) {
            await this.$refs?.options?.addColor()
          } else {
            this.setData({ activeTab: 3 })
          }
          break
        }
        case 3: {
          if (!this.$refs?.productImage?.imagesList.length) {
            this.$refs.productImage.upload()
          } else {
            await this.$refs.productImage.saveImages()
            this.setData({ activeTab: 4 })
          }
          break
        }
        case 4: {
          if (this.$refs.discount.selectedDiscountsList.length) {
            this.$refs.discount.saveEquipmentDiscount()
          } else {
            this.cancel()
          }
          break
        }
      }
    },

    cancel() {
      this.toggleOpenModal(false);

      this.setData({
        isEditMode: false,
        activeTab: 0,
        disableTabs: 0,
      });

      this.resetEquipmentData()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.parent-add-provider-modal {
  display: flex;

  .left {
    min-width: 226px;
    padding: 18px 14px 24px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    & .list {
      padding-left: 0;
      overflow-y: scroll;
      height: 618px;
      text-transform: capitalize;

      li + li {
        margin-top: 3px;
      }

      & > li {
        padding: 7px 11px;
        cursor: pointer;
        border-radius: 4px;
        font: normal normal 600 14px Segoe UI;
        white-space: nowrap;
        transition: $transition;

        &.P-disable-tab {
          color: $neutral-color;
          cursor: default;
        }

        &:hover:not(.P-disable-tab) {
          background-color: $neutral-color25;
          color: $secondary-color;
        }
      }
    }
  }

  .right {
    padding: 16px 24px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #a5aac6;
      margin: 24px -24px 0 -24px;
      padding: 24px 24px 0;
    }
    ::v-deep {
      .P-modal-component-header {
        margin-bottom: 0;
      }
    }
  }
}

.percent-icon {
  width: 38px;
  height: 38px;
}
</style>
