<template>
  <div class="prices P-modal-components">
    <div class="d-flex flex-wrap P-inputs">
      <div class="P-padding-33">
        <ott-select
          v-model="saleRent"
          :items="saleRateItems"
          @emitFunc="saleRentChange()"
          label="Sale/Rent"
          selected-text="name"
        />
      </div>
    </div>

    <div v-if="priceList.length && saleRent">
      <div
        v-if="pieceRanges.length && filteredPriceList.length"
        class="d-flex prices__pieces-cont mb-8 mt-3"
      >
        <p
          v-for="(item, key) of pieceRanges"
          :key="key"
          @click="activePiece = { ...item }"
          :class="{
            'active-piece':
              activePiece?.min === item.min && activePiece?.max === item.max,
          }"
          class="prices__piece-txt px-4 pb-2 mb-0"
        >
          {{ item.min }} - {{ item.max }} Piece
        </p>
      </div>

      <div v-if="filteredPricesByMinMax.length" class="d-flex align-start">
        <table class="border">
          <tr>
            <th v-for="(item, key) of tableHeaders" :key="key">{{ item }}</th>
          </tr>

          <tr
            v-for="(item, key) of filteredPricesByMinMax"
            :key="key"
            class="prices__piece-tr P-hovered"
          >
            <td v-if="saleRent === 2" class="prices__md-td">{{ item.md }}</td>
            <td
              @click.stop.prevent="
                !item.priceGroup && item.showPriceGroupSelect === false
                  ? (item.showPriceGroupSelect = true)
                  : ''
              "
              class="prices__price-group-td"
            >
              <p
                v-if="!item.showPriceGroupSelect && item.priceGroup"
                @click="
                  item.priceGroup ? (item.showPriceGroupSelect = true) : ''
                "
                class="mb-0"
              >
                {{ getPriceGroupName(item.priceGroup) }}
              </p>

              <ott-select
                v-if="item.showPriceGroupSelect"
                v-model="item.priceGroup"
                @emitFunc="updatePriceList()"
                @emitInputChange="item.showPriceGroupSelect = false"
                :items="priceGroups"
                selected-text="name"
                label="Price Group"
              />
            </td>

            <template v-if="item.pieces.length">
              <td
                v-for="(piece, i) of item.pieces"
                :key="i"
                @click.stop.prevent="
                  !piece.price && piece.showInp === false
                    ? (piece.showInp = true)
                    : ''
                "
                class="prices__piece-td"
              >
                <p
                  v-if="!piece.showInp && piece.price"
                  @click="piece.price ? (piece.showInp = true) : ''"
                  class="mb-0"
                >
                  ${{ piece.price }}
                </p>

                <ott-input
                  v-if="piece.showInp"
                  v-model.number="piece.price"
                  @timeoutInput="updatePriceList()"
                  :timeoutInput="true"
                  @onChange="piece.showInp = false"
                  :blur="() => (piece.showInp = false)"
                  :min="0.1"
                  is-percent
                  customInputClass="mx-auto"
                  type="number"
                />
                <span
                  v-if="i === item.pieces.length - 1 && !piece.showInp"
                  @click="removePrice(key)"
                  class="mdi mdi-close prices__piece-remove-icon"
                ></span>
              </td>
            </template>
          </tr>
        </table>

        <div
          @click="addColumn()"
          class="prices__circle-plus cursor-pointer d-flex align-center justify-center ms-2"
        >
          <span>+</span>
        </div>
      </div>

      <div
        v-if="filteredPriceListByMinMax.length && saleRent === 2"
        class="d-flex justify-center mt-2"
      >
        <div
          @click="addRow()"
          class="prices__circle-plus cursor-pointer d-flex align-center justify-center"
        >
          <span>+</span>
        </div>
      </div>

      <v-dialog v-model="showAddModal" max-width="400" class="add-modal">
        <v-card>
          <v-card-title class="add-modal__header px-4 py-2">
            {{ addModalTitle }}
          </v-card-title>

          <hr class="add-modal__hr mx-4 mb-2" />

          <div class="d-flex flex-wrap px-4">
            <div v-if="isAddColumn" class="P-padding-100 px-0">
              <ott-input
                v-model.number="columnQuantity"
                :min="0"
                type="number"
                label="Quantity"
              />
            </div>

            <div v-if="isAddRow" class="P-padding-100 px-0">
              <ott-input
                v-model.number="numberOfMD"
                :min="1"
                type="number"
                label="Number of M/D"
              />
            </div>

            <v-radio-group v-if="isAddRow" v-model="dayMonthType">
              <div class="P-full-line G-flex">
                <div class="P-radio-box me-6">
                  <ott-radio value="m">
                    <span class="txt-neutral" slot="customLabel">Month</span>
                  </ott-radio>
                </div>

                <div class="P-radio-box">
                  <ott-radio value="d">
                    <span class="txt-neutral" slot="customLabel">Day</span>
                  </ott-radio>
                </div>
              </div>
            </v-radio-group>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <ott-button-loader
              @onClick="addRowColumnSave"
              :is-loading="isLoading"
              className="text-capitalize primary-sm-btn mb-2"
              button-text="Save"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div
      v-if="!filteredPriceListByMinMax.length && saleRent"
      class="P-add-price"
    >
      <title-component v-if="saleRent === 2" title-text="Type" />

      <div v-if="saleRent === 2" class="d-flex flex-wrap align-center">
        <v-radio-group v-model="dayMonthType">
          <div class="d-flex flex-wrap">
            <div class="P-radio ms-2 me-3">
              <ott-radio label="Day" value="d" />
            </div>
            <div class="P-radio">
              <ott-radio label="Month" value="m" />
            </div>
          </div>
        </v-radio-group>

        <div class="P-padding-33">
          <ott-input
            v-model.number="numberOfMD"
            type="number"
            :label="saleRent === 1 ? 'Number of day' : 'Number of month'"
            :error="$v.priceTab.numberOfMD.$error"
            :min="1"
            custom-input-class="P-number-month"
          />
        </div>
      </div>

      <div class="P-default-prices">
        <title-component title-text="Default Prices" />
        <div class="d-flex flex-wrap">
          <div class="P-padding-20">
            <ott-input
              v-model.number="firstPc"
              :error="$v.priceTab.firstPc.$error"
              :min="0.1"
              type="number"
              label="1 Pc"
              is-percent
            />
          </div>

          <div class="P-padding-20">
            <ott-input
              v-model.number="secondPc"
              :error="$v.priceTab.secondPc.$error"
              :min="0.1"
              type="number"
              label="2 Pc"
              is-percent
            />
          </div>

          <div class="P-padding-20">
            <ott-input
              v-model.number="thirdPc"
              :error="$v.priceTab.thirdPc.$error"
              :min="0.1"
              type="number"
              label="3 Pc"
              is-percent
            />
          </div>

          <div class="P-padding-20">
            <ott-input
              v-model.number="fourthPc"
              :error="$v.priceTab.fourthPc.$error"
              :min="0.1"
              type="number"
              label="4 Pc"
              is-percent
            />
          </div>

          <div class="P-padding-20">
            <ott-input
              v-model.number="fifthPc"
              :error="$v.priceTab.fifthPc.$error"
              :min="0.1"
              type="number"
              label="5 Pc"
              is-percent
            />
          </div>
        </div>
      </div>
    </div>

    <div class="P-padding-20 pt-6 pl-0">
      <ott-button-loader
        v-if="isEditMode"
        @onClick="savePrices"
        :is-loading="isLoading"
        :button-text="'Save'"
        className="primary-sm-btn P-login-btn"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import _ from "lodash";
import TitleComponent from "@/components/TitleComponent";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "Price",
  components: {
    TitleComponent,
    OttInput,
    OttSelect,
    OttRadio,
    OttButtonLoader,
  },
  validations: {
    priceTab: {
      numberOfMD: {
        required: requiredIf(function () {
          return (
            !this.filteredPriceList.length &&
            this.showFormErrors &&
            this.saleRent === 2
          );
        }),
      },
      firstPc: {
        required: requiredIf(function () {
          return !this.filteredPriceList.length && this.showFormErrors;
        }),
      },
      secondPc: {
        required: requiredIf(function () {
          return !this.filteredPriceList.length && this.showFormErrors;
        }),
      },
      thirdPc: {
        required: requiredIf(function () {
          return !this.filteredPriceList.length && this.showFormErrors;
        }),
      },
      fourthPc: {
        required: requiredIf(function () {
          return !this.filteredPriceList.length && this.showFormErrors;
        }),
      },
      fifthPc: {
        required: requiredIf(function () {
          return !this.filteredPriceList.length && this.showFormErrors;
        }),
      },
    },
  },
  data() {
    return {
      showAddModal: false,
      addModalTitle: "",
      isAddRow: false,
      isAddColumn: false,
      columnQuantity: null,
      dayMonthType: null,
      saleRateItems: [
        {
          name: "Sale",
          value: 1,
        },
        {
          name: "Rent",
          value: 2,
        },
      ],
      activePiece: null,
      priceGroups: [],
      filteredPricesByMinMax: [],
      showFormErrors: false,
    };
  },
  watch: {
    // watched filteredPriceListByMinMax every changes, and set list to filteredPricesByMinMax for get access to change inside keys
    filteredPriceListByMinMax: {
      deep: true,
      handler(newList) {
        let md = this.saleRent === 1 ? "sale" : "";
        this.filteredPricesByMinMax = _.clone(newList);
        this.filteredPricesByMinMax.sort((a, b) => {
          const [aNum, aStr] = a.md.split(md);
          const [bNum, bStr] = b.md.split(md);
          return Number(aNum) - Number(bNum) || aStr.localeCompare(bStr);
        });
      },
    },
  },
  computed: {
    ...mapState({
      selectedEquipmentId: (state) =>
        state.equipmentsModule.selectedEquipmentId,
      isEditMode: (state) => state.equipmentsModule.isEditMode,
      priceTab: (state) => state.equipmentsModule.equipmentData.priceTab,
      priceList: (state) =>
        state.equipmentsModule.equipmentData.priceTab.priceList,
      priceGroupList: (state) => state.priceGroupAdmin.priceGroupList,
      isLoading: (state) => state.appModule.isLoading,
    }),

    saleRent: {
      get() {
        return this.priceTab.saleRent;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "priceTab",
          secondLevel: "saleRent",
          value,
        });
      },
    },

    numberOfMD: {
      get() {
        return this.priceTab.numberOfMD;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "priceTab",
          secondLevel: "numberOfMD",
          value,
        });
      },
    },

    firstPc: {
      get() {
        return this.priceTab.firstPc;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "priceTab",
          secondLevel: "firstPc",
          value,
        });
      },
    },

    secondPc: {
      get() {
        return this.priceTab.secondPc;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "priceTab",
          secondLevel: "secondPc",
          value,
        });
      },
    },

    thirdPc: {
      get() {
        return this.priceTab.thirdPc;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "priceTab",
          secondLevel: "thirdPc",
          value,
        });
      },
    },

    fourthPc: {
      get() {
        return this.priceTab.fourthPc;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "priceTab",
          secondLevel: "fourthPc",
          value,
        });
      },
    },

    fifthPc: {
      get() {
        return this.priceTab.fifthPc;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "priceTab",
          secondLevel: "fifthPc",
          value,
        });
      },
    },

    pieceRanges() {
      let ranges = [];
      if (this.filteredPriceList.length) {
        return this.getPiecesRanges(this.filteredPriceList[0].pieces);
      }
      return ranges;
    },

    tableHeaders() {
      const headers = [];

      if (this.saleRent === 1) {
        headers[0] = "Price Group";
      }

      if (this.saleRent === 2) {
        headers[0] = "M/D";
        headers[1] = "Price Group";
      }
      if (this.filteredPriceListByMinMax.length) {
        if (this.filteredPriceListByMinMax[0].pieces.length) {
          this.filteredPriceListByMinMax[0].pieces.map((item) => {
            headers.push(item.piece + "Pc");
          });
        }
      }
      return headers;
    },

    filteredPriceList() {
      let priceList = [];

      if (this.priceList.length) {
        // let dayMonth =
        //   this.saleRent === 1 ? "d" : this.saleRent === 2 ? "m" : " ";
        priceList = this.priceList.filter((item) =>
          this.saleRent === 1
            ? item.md.includes("sale")
            : item.md.includes("d") || item.md.includes("m")
        );
      }

      return priceList;
    },

    filteredPriceListByMinMax() {
      // if activePiece is selected, filter list by activePiece.min between this.activePiece.max
      if (
        this.activePiece &&
        this.filteredPriceList.length &&
        this.priceList.length
      ) {
        const filteredPriceList = JSON.parse(
          JSON.stringify(this.filteredPriceList)
        );
        return filteredPriceList.map((obj) => {
          obj.pieces = obj.pieces.filter(
            (piece) =>
              piece.piece >= this.activePiece.min &&
              piece.piece <= this.activePiece.max
          );
          return obj;
        });
      }

      return [];
    },
  },
  async created() {
    this.priceGroups.push({
      name: "Default",
      value: "default",
    });

    await this.getPriceGroupList().then((data) => {
      if (data.results) {
        data.results.map((item) =>
          this.priceGroups.push({
            name: item.name[0].name,
            value: item.id,
          })
        );
      }
    });
  },
  mounted() {
    if (this.pieceRanges.length && !this.activePiece) {
      this.activePiece = { ...this.pieceRanges[0] };
    }
  },
  methods: {
    ...mapMutations({
      setEquipmentData: "equipmentsModule/setEquipmentData",
      resetEquipmentDataPriceForm:
        "equipmentsModule/resetEquipmentDataPriceForm",
    }),

    ...mapActions({
      createEquipment: "equipmentsModule/createEquipment",
      getEquipmentData: "equipmentsModule/getEquipmentData",
      getPriceGroupList: "priceGroupAdmin/getPriceGroupList",
      updateEquipment: "equipmentsModule/updateEquipment",
    }),

    addRow() {
      this.isAddRow = true;
      this.isAddColumn = false;
      this.addModalTitle = "Add Row";
      this.showAddModal = true;
    },

    addColumn() {
      this.isAddRow = false;
      this.isAddColumn = true;
      this.addModalTitle = "Add Column";
      this.showAddModal = true;
    },

    addRowColumnSave() {
      if (this.isAddColumn) {
        let duplicatedPieces = 0;

        // check if columnQuantity number is not duplicatated than push
        if (this.priceList.length && this.columnQuantity) {
          if (this.priceList[0].pieces.length) {
            this.priceList[0].pieces.map((item) => {
              if (this.columnQuantity === item.piece) {
                duplicatedPieces += 1;
              }
            });

            if (!duplicatedPieces) {
              this.priceList.map((item) =>
                item.pieces.push({
                  piece: this.columnQuantity,
                  showInp: false,
                  price: null,
                })
              );
            }
          } else {
            this.priceList.map((item) =>
              item.pieces.push({
                piece: this.columnQuantity,
                showInp: false,
                price: null,
              })
            );
          }
        }
      }

      if (this.isAddRow && this.numberOfMD && this.dayMonthType) {
        let duplicatedRentRows = 0;

        // object for add price row
        const priceRow = {
          priceGroup: null,
          showPriceGroupSelect: false,
          pieces: [],
        };

        if (this.saleRent === 2) { // add md for rent price
          priceRow.md = this.numberOfMD + this.dayMonthType
        }

        // all new prices should have same pieces for that get first price's pieces
        if (this.priceList[0]) {
          priceRow.pieces = JSON.parse(
            JSON.stringify(this.priceList[0].pieces)
          );
        }

        priceRow.pieces.map((item) => {
          item.price = null;
          item.showInp = false;
        });

        // check is duplicated new row or not
        this.priceList.map((item) => {
          if (item.md === this.numberOfMD + this.dayMonthType) {
            duplicatedRentRows += 1;
          }
        });

        if (this.saleRent === 2 && !duplicatedRentRows) {
          this.priceList.push(priceRow);
        }
      }

      this.columnQuantity = null;
      this.showAddModal = false;
      this.isAddColumn = false;
    },

    getPriceGroupName(id) {
      let name = "";
      if (id === "default") {
        name = "Default";
      }

      if (id !== "default" && this.priceGroups.length) {
        this.priceGroups.map((item) => {
          if (item.value === id) {
            name = item.name;
          }
        });
      }
      return name;
    },

    async removePrice(index) {
      this.priceList.splice(index, 1);

      await this.updateEquipment({
        prices: this.priceList.length ? [...this.priceList] : [],
      });
    },

    getPiecesRanges(pieces) {
      // Sort the pieces array by the 'piece' property
      pieces.sort((a, b) => a.piece - b.piece);

      const ranges = [];
      let currentRange = { min: pieces[0].piece, max: pieces[0].piece };

      // Loop through the sorted pieces array and group them into ranges
      for (let i = 1; i < pieces.length; i++) {
        if (pieces[i].piece === currentRange.max + 1) {
          currentRange.max = pieces[i].piece;
        } else {
          ranges.push(currentRange);
          currentRange = { min: pieces[i].piece, max: pieces[i].piece };
        }
      }

      // Push the last range into the ranges array
      ranges.push(currentRange);

      // Check if any of the ranges should be merged
      for (let i = 0; i < ranges.length - 1; i++) {
        if (ranges[i].max + 1 === ranges[i + 1].min) {
          ranges[i].max = ranges[i + 1].max;
          ranges.splice(i + 1, 1);
          i--;
        }
      }

      // Create a new array with the desired output format
      const result = [];
      for (const range of ranges) {
        result.push({ min: range.min, max: range.max });
      }

      // Assign the correct rangeStart value to each range in the result array
      return result.map((range, index) => ({
        min: index === 0 ? 1 : ranges[index - 1].max + 1,
        max: range.max,
      }));
    },

    saleRentChange() {
      if (this.pieceRanges.length && !this.activePiece) {
        this.activePiece = { ...this.pieceRanges[0] };
      }
    },

    updatePriceList() {
      // check filteredPricesByMinMax has changes, get changed list update inside priseList
      this.priceList.forEach((item) => {
        item.pieces.forEach((piece) => {
          this.filteredPricesByMinMax.forEach((newItem) => {
            if (item.md === newItem.md) {
              item.priceGroup = newItem.priceGroup;
              const newPiece = newItem.pieces.find(
                (p) => p.piece === piece.piece
              );
              if (newPiece) {
                piece.price = newPiece.price;
              }
            }
          });
        });
      });
    },

    async savePrices() {
      this.$v.priceTab.$touch();
      this.showFormErrors = true;

      if (!this.$v.priceTab.$error) {
        let priceRow = [];

        if (!this.filteredPriceList.length) {
          priceRow = {
            priceGroup: null,
            showPriceGroupSelect: false,
            pieces: [
              {
                piece: 1,
                price: this.firstPc,
                showInp: false,
              },
              {
                piece: 2,
                price: this.secondPc,
                showInp: false,
              },
              {
                piece: 3,
                price: this.thirdPc,
                showInp: false,
              },
              {
                piece: 4,
                price: this.fourthPc,
                showInp: false,
              },
              {
                piece: 5,
                price: this.fifthPc,
                showInp: false,
              },
            ],
          };

          if (this.saleRent === 1) { // add md for sale
             priceRow.md = 'sale'
          }

          if (this.saleRent === 2) { // add md for rent
            priceRow.md = this.numberOfMD + this.dayMonthType;
          }

          this.priceList.push({ ...priceRow }); // Need to remove after server working
          this.showFormErrors = false;
        }

        const priceList = _.clone(this.priceList)
        priceList.map(item => delete item._id)

        // if (information tab not has error than) need to check 
        if (this.selectedEquipmentId) {
          await this.updateEquipment({
            prices: !this.filteredPriceList.length
              ? [{ ...priceRow }]
              : [...priceList],
          });
          await this.getEquipmentData(this.selectedEquipmentId);
        } else {
          await this.createEquipment()
        }
      }

      // after save priceList set activeRange
      if (this.pieceRanges.length && !this.activePiece) {
        this.activePiece = { ...this.pieceRanges[0] };
      }

      this.resetEquipmentDataPriceForm();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";
@import "src/assets/scss/tableList";

.P-inputs {
  margin: 0 -10px;
}
.prices {
  &__pieces-cont {
    border-bottom: 1px solid #d7d9e5;
    max-width: 773px;
    overflow-y: hidden;
    overflow-x: auto;
  }

  &__piece-txt {
    white-space: nowrap;
    font-weight: bold;
    color: $neutral-color;
    position: relative;
    top: 2px;
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 3px solid transparent;
  }

  &__circle-plus {
    width: 18px;
    height: 18px;
    transition: all 0.3s;
    border-radius: 50%;
    color: $neutral-color;
    border: 1px solid $neutral-color;
    font-weight: 500;

    & span {
      margin-bottom: 1px;
    }

    &:hover {
      color: white;
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  table {
    max-width: 745px;
    overflow-x: auto;
  }

  &__md-td {
    width: 80px;
  }

  &__price-group-td {
    width: 120px;
  }

  &__piece-tr {
    &:hover {
      .prices__piece-remove-icon {
        display: block;
      }
    }
  }

  &__piece-td {
    width: 80px;
    position: relative;
  }

  &__piece-remove-icon {
    cursor: pointer;
    display: none;
    color: red;
    position: absolute;
    top: 25%;
    right: 5px;
  }

  td {
    height: 38px;
  }
}

.theme--dark {
  .P-hovered {
      &:hover {
          & td {
              background-color: transparent !important;
          }
      }
  }
}

.add-modal {
  &__hr {
    background-color: #d7d9e5;
    border: unset;
    height: 1px;
  }
}

.active-piece {
  color: $primary-color !important;
  border-bottom: 3px solid $primary-color;
}

.txt-neutral {
  color: $neutral-color;
}
</style>

<style lang="scss">
.prices {
  .v-input__slot {
    margin-bottom: 0 !important;
  }
}
</style>