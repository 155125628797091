<template>
  <div class="position-relative">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title">
          <h3 class="secondary--text">Equipments</h3>
        </div>
        <ott-button
          v-if="permissionIs('createEquipment')"
          :click="openModal"
          class="primary-sm-btn"
          custom-class="approve"
          icon-before
          text="Add Equipments"
          icon-before-class="mdi mdi-plus d-inline"
        />
      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <OttCheckbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
                tooltip="Select all"
              />
            </div>
            <div
              v-if="
                (selectedArrowList.length || selectedArrowListSub.length) &&
                permissionIs('deleteEquipment')
              "
              class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectMultipleAction('delete')"
                  >
                    <span class="mdi mdi-delete-outline neutral--text P-icon" />
                  </div>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
            <div
              v-if="
                (selectedArrowList.length || selectedArrowListSub.length) &&
                permissionIs('equipmentEnableDisableAction')
              "
              class="ott-menu select-all-menu-cont P-settings"
            >
              <v-menu offset-y attach=".select-all-menu-cont">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="P-settings"
                    :ripple="false"
                  >
                    <span
                      class="P-icon mdi mdi-dots-vertical G-flex-center"
                      :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}"
                    ></span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(action, index) of multipleActions"
                    :key="index"
                    @click="selectMultipleAction(action.value)"
                  >
                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="G-excel-btn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="P-settings"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportData"
                >
                  <span class="mdi mdi-file-excel neutral--text P-icon" />
                </div>
              </template>
              <span>Download Excel</span>
            </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            :column-config="columnConfigData"
            :is-default="isDefault"
            @resetDataSettingsTable="resetDataTableSettings"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            class="P-columns"
          />
        </div>
        <div>
          <div
            class="G-filter-btn"
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              v-if="filterModel.filterCount.length > 0"
              class="active-filter-count"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        class="G-component-body page-background"
      >
        <div class="G-sub-block-title">
          <h3 class="secondary--text">Equipments List</h3>
        </div>
        <OttUniversalTable
          v-if="!loading"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="equipmentsModule.dataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="equipmentsModule.dataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortData"
          @updateColumnWith="updateColumn"
        >
          <template
            v-if="
              permissionIs('updateEquipment') ||
              permissionIs('deleteEquipment') ||
              permissionIs('equipmentEnableDisableAction')
            "
            slot-scope="props"
            slot="action"
          >
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in actionsList(props.rowData.enableForSale)"
                  :key="i"
                  @click="selectAction($event, props, item.value)"
                >
                  <div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
          v-model="rowsPerPage"
          v-if="equipmentsModule.equipmentsResult && !loading"
          :data-count="equipmentsModule.dataCount"
          :available-data-count="equipmentsModule.dataTable.length"
          :current-page="equipmentsModule.currentPage"
          :page-limit="equipmentsModule.totalPages"
          @update="updateLimit"
          @updatePage="updateEquipmentsTable"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      class="G-filter-component page-background"
      :class="{ 'P-filter-open': isOpenFilter }"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList"  />
      </div>
      <div class="G-filter-footer">
        <ott-button
          class="primary-lg-btn"
          text="Reset Filters"
          :click="resetData"
        >
        </ott-button>
      </div>
    </div>
    <PageLoader v-if="equipmentsModule.isTableLoading || loading" />
    <ManageEquipment v-if="equipmentsModule.modal.isOpen" />
    <WarningModal
      v-if="equipmentsModule.modal.showWarningModal"
      :isModalOpen="equipmentsModule.modal.showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="closeWarningModal"
      @btnFunction="warningModalBtnFunction"
    />

    <image-modal
      v-if="isShowImage"
      @closeModal="closeImageModal()"
      @imageChange="saveImageChanges($event)"
      :isOpenModal="isShowImage"
      :showEditBtn="true"
      :imageSrc="selectedImageSrc"
      modal-title="Equipment Image"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import FilterMixin from "@/mixins/FilterMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import PageLoader from "@/components/pageLoader/PageLoader";
import WarningModal from "@/components/WarningModal";
import NoDataInTable from "@/components/NoDataInTable";
import FilterData from "./components/FilterData";
import ManageEquipment from "./modals/ManageEquipment.vue";
import ImageModal from "@/components/ImageModal";

export default {
  components: {
    OttButton,
    OttCheckbox,
    OttUniversalTable,
    OttHideShowColumnsOption,
    OttPaginationForTable,
    PageLoader,
    WarningModal,
    NoDataInTable,
    FilterData,
    ManageEquipment,
    ImageModal
  },
  mixins: [OttUniversalTableForComponentsMixin, FilterMixin, ColumnConfigMixin],
  data() {
    return {
      columnConfigData: [],
      defaultColumnConfigData: [],
      actions: [
        { title: "Edit", value: "edit" },
        { title: "Delete", value: "delete" },
        { title: "Enable", value: "enable" },
        { title: "Disable", value: "disable" },
      ],
      multipleActions: [
        { title: "Enable", value: "enable" },
        { title: "Disable", value: "disable" },
      ],
      warningModalIsLoading: false,
      warningModalBtnText: "",
      warningModalType: "",
      warningModalIsDangerButton: true,
      isShowImage: false,
      selectedImageSrc: null,
      selectedImageId: null,
      loading: false
    };
  },
  computed: {
    ...mapState({
      isDefault: (state) => state.equipmentsModule.isDefault,
      equipments: (state) => state.equipmentsModule,
      filterModel: (state) => state.equipmentsModule.filterModel,
      equipmentsModule: (state) => state.equipmentsModule,
      dataTable: (state) => state.equipmentsModule.dataTable,
      filter: (state) => state.equipmentsModule.filter,
      equipmentTypes: (state) => state.equipmentsModule.types,
      selectedEquipmentId: (state) =>
        state.equipmentsModule.selectedEquipmentId,
      selectedEquipments: (state) => state.equipmentsModule.equipmentModel,
      updateColumnsFromBack: (state) =>
        state.equipmentsModule.updateColumnsFromBack,
      updateColumnSettingsCommit: (state) =>
        state.equipmentsModule.updateColumnSettingsCommit,
      defaultSettings: (state) => state.equipmentsModule.defaultSettings,
      discountsList: (state) => state.discountModule.dataTable,
    }),

    defaultColumnConfigs() {
      return [
        {
          title: "ID",
          isTranslate: false,
          style: { minWidth: 60 },
          defaultHide: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          key: "number",
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "number",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${row.number ? row.number : "-"}</p>`,
        },
        {
          title: "Image",
          isTranslate: false,
          style: { minWidth: 140 },
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          key: "image",
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: false,
            sortKey: "image",
            sortValue: "",
          },
          cellView: (row) =>
            {
              if (row?.productImage?.imagesList) {
                if (row.productImage.imagesList.length) {
                  return  `<img src="${process.env.VUE_APP_API_URL + 'files/icon/' + row.productImage.imagesList[0]}" data-equipment="${row.id}" data-src="${row.productImage.imagesList[0]}" class="equipment-image cursor-pointer" />`
                } else {
                  return '-'
                }
              } else {
                return '-'
              }
            }
           
        },
        {
          title: "Name",
          isTranslate: false,
          style: { minWidth: 160 },
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          key: "name",
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "name",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.name.length
                ? row.name.map((name) =>
                    name.lang === "en" ? name.name : row.name[0].name
                  )[0]
                : "-"
            }</p>`,
        },
        {
          title: "Description",
          isTranslate: false,
          style: { minWidth: 320 },
          defaultHide: true,
          defaultDragIndex: 3,
          mainIndex: 3,
          key: "description",
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "description",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.description ? row.description : "-"
            }</p>`,
        },
        {
          title: "Type",
          isTranslate: true,
          style: { minWidth: 160 },
          defaultHide: true,
          defaultDragIndex: 4,
          mainIndex: 4,
          key: "type",
          isShow: true,
          isDrag: true,
          cellView: (row) =>
            `<p class="G-text-table">${this.getTypes(row.type)}</p>`,
        },
        {
          title: "Price",
          isTranslate: false,
          style: { minWidth: 40 },
          defaultHide: true,
          defaultDragIndex: 5,
          mainIndex: 5,
          key: "price",
          isShow: true,
          isDrag: true,
          cellView: (row) =>
            `<p class="G-text-table">${
              row.prices ? this.getOnePiecePrice(row.prices) : "-"
            }</p>`,
        },
        {
          title: "Status",
          isTranslate: false,
          style: { minWidth: 160 },
          defaultHide: true,
          defaultDragIndex: 6,
          mainIndex: 6,
          key: "enableForSale",
          isShow: true,
          isDrag: true,
          cellView: (row) =>
            `<p class="G-text-table P-time-line-data G-badge ${
              !row.enableForSale ? "G-disabled-badge" : "G-approve-badge"
            }">${row.enableForSale ? "Active" : "Disabled"}</p>`,
        },
        {
          title: "Discounts",
          isTranslate: false,
          style: { minWidth: 160 },
          defaultHide: true,
          defaultDragIndex: 7,
          mainIndex: 7,
          key: "discounts",
          isShow: true,
          isDrag: true,
          cellView: (row) =>
            `<p class="G-text-table">${ this.getDiscountsNames(row?.discount?.selectedDiscounts) }</p>`,
        },
      ];
    },

    rowsPerPage: {
      set(limit) {
        this.setLimit(limit)
      },
      get() {
        return this.filter.limit;
      }
    },

    warningModalHeading() {
      return `${this.warningModalBtnText} Equipments(s) ?`;
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Equipments! <br />  If you're not sure, you can cancel this operation.`;
    },
  },
  async created() {
    this.loading = true;
    await this.getEquipmentsSettings();
    this.createColumnConfigData();
    this.sortColumn();
    await Promise.all([
      this.getEquipmentsList().finally(() => this.loading = false),
      this.getEquipmentsTypes(),
      this.getDiscountList({
        limit: 10000,
        type: 2,
      })
    ])

    this.resetSelectedList()
    this.addClickFuncToImages()
  },

  beforeDestroy() {
    this.filterModel.reset()
    this.setData({ filterData: {} })
  },

  destroyed() {
    this.setFilterPage(1)
  },

  methods: {
    ...mapActions({
      updateEquipment: "equipmentsModule/updateEquipment",
      getEquipmentsList: "equipmentsModule/getEquipmentsList",
      getEquipmentsSettings: "equipmentsModule/getEquipmentsSettings",
      getEquipmentsTypes: "equipmentsModule/getEquipmentsTypes",
      deleteEquipments: "equipmentsModule/deleteEquipments",
      getEquipmentData: "equipmentsModule/getEquipmentData",
      enableDisableAction: "equipmentsModule/enableDisableAction",
      getDiscountList: "discountModule/getDiscountList",
      deleteImage: "fileUpload/deleteImage",
      uploadImage: "fileUpload/uploadImage",
    }),
    ...mapMutations({
      setData: "equipmentsModule/setData",
      setLimit: "equipmentsModule/setLimit",
      toggleOpenModal: "equipmentsModule/toggleOpenModal",
      showWarningModal: "equipmentsModule/showWarningModal",
      selectEquipments: "equipmentsModule/selectEquipments",
      sortColumn: "equipmentsModule/sortColumn",
      setFilterPage: "equipmentsModule/setFilterPage",
      filterEquipments: "equipmentsModule/filterEquipments",
    }),

    addClickFuncToImages() {
      // For all equipments images add click event, to get img src and show it in modal
      const equipmentsImages = document.querySelectorAll('.equipment-image');
      equipmentsImages.forEach(image => {
        image.addEventListener('click', () => {
          this.selectedImageSrc = image.src
          this.selectedImageId  = image.getAttribute('data-src')
          this.setData({ selectedEquipmentId: image.getAttribute('data-equipment') })
          this.isShowImage = true
        });
      });
    },
    
    async updateEquipmentsTable(page, showLoader = true) {
      if (page) this.setFilterPage(page);
      this.sortColumn();
      await this.getEquipmentsList();
      this.resetSelectedList()
    },
    async exportData() {
      this.filterEquipments();
      await this.getEquipmentsList({ excel: true });
      this.resetSelectedList()
    },
    async updateLimit() {
      await this.$store
        .dispatch(this.updateColumnsFromBack, this.defaultSettings)
        .then(() => {
          this.createSaveDefaultData();
        });
      this.sortColumn();

      await this.getEquipmentsList();
      this.resetSelectedList()
    },
    openModal() {
      if (!this.equipmentsModule.isEditMode) {
        this.setData({ selectedEquipmentId: "" });
      }
      this.toggleOpenModal(true);
    },
    closeWarningModal() {
      this.setData({ selectedEquipmentId: "" });
      this.showWarningModal(false);
    },
    actionsList(enableForSale) {
      const actions = [];

      if (this.permissionIs("updateEquipment")) {
        actions.push({ title: "Edit", value: "edit" });
      }

      if (this.permissionIs("deleteEquipment")) {
        actions.push({ title: "Delete", value: "delete" });
      }

      if (this.permissionIs("equipmentEnableDisableAction")) {
        if (!enableForSale) {
          actions.push({ title: "Enable", value: "enable" });
        } else {
          actions.push({ title: "Disable", value: "disable" });
        }
      }

      return actions;
    },
    selectMultipleAction(actionName) {
      if (this.selectedArrowList.length) {
        // const idList = this.selectedArrowList.map((x) => x._id);
        // this.selectEquipments(idList);
        switch (actionName) {
          case "delete": {
            this.warningModalType = "multipleDelete";
            this.warningModalBtnText = "Delete";
            this.warningModalIsDangerButton = true;
            this.showWarningModal(true);
            break;
          }
        }
        switch (actionName) {
          case "enable": {
            this.warningModalType = "multipleEnable";
            this.warningModalBtnText = "Enable";
            this.warningModalIsDangerButton = false;
            this.showWarningModal(true);
            break;
          }
        }
        switch (actionName) {
          case "disable": {
            this.warningModalType = "multipleDisable";
            this.warningModalBtnText = "Disable";
            this.warningModalIsDangerButton = true;
            this.showWarningModal(true);
            break;
          }
        }
      }
    },
    async warningModalBtnFunction() {
      let equipments = []

      if (this.selectedArrowList.length) {
        this.warningModalIsLoading = true;
         equipments = this.selectedArrowList.map((x) => x._id);
        this.selectEquipments(equipments);
      }

      const body = {}

      if (this.selectedEquipmentId) {
        body.equipmentId = [this.selectedEquipmentId];
      }

      if (this.isSelectedAll) {
        body.all = true
      }

      switch (this.warningModalType) {
        case "delete":
          await this.deleteEquipments({ equipmentId: [this.selectedEquipmentId] })
            .then()
            .finally(() => (this.warningModalIsLoading = false));
          break;
        case "enable":
          body.enableForSale = true
          await this.acceptDisableEnable(body).finally(
            () => (this.warningModalIsLoading = false)
          );
          break;
        case "disable":
          body.enableForSale = false
          await this.acceptDisableEnable(body).finally(
            () => (this.warningModalIsLoading = false)
          );
          break;
        case "multipleDelete":
          if (!this.isSelectedAll) {
            body.equipmentId = [...equipments]
          }

          await this.deleteEquipments(body).finally(
            () => (this.warningModalIsLoading = false)
          );
          break;
        case "multipleEnable":
          body.enableForSale = true;
          if (!this.isSelectedAll) {
            body.equipmentId = [...equipments]
          }

          await this.acceptDisableEnable(body).finally(
            () => (this.warningModalIsLoading = false)
          );
          break;
        case "multipleDisable":
          body.enableForSale = false;
          if (!this.isSelectedAll) {
            body.equipmentId = [...equipments]
          }
          await this.acceptDisableEnable(body).finally(
            () => (this.warningModalIsLoading = false)
          );
          break;
      }

      this.selectedArrowList = []
      this.selectAllList = false
    },

    async acceptDisableEnable(data) {
      await this.enableDisableAction(data).then(async () => {
        await this.getEquipmentsList();
        this.resetSelectedList()
        this.showWarningModal(false);
      });
    },

    async sortData(data) {
      this.sortColumn(data);
      await this.getEquipmentsList();
    },

    async resetData() {
      this.filterModel.reset();
      this.filterEquipments();
      await this.getEquipmentsList();
      this.resetSelectedList()
    },

    async resetDataTableSettings() {
      await this.resetDataTable()
      await this.getEquipmentsSettings();
      this.createColumnConfigData();
    },
    selectAction() {
      const [event, data, actionName] = arguments;
      this.setData({ selectedEquipmentId: data.rowData.id });

      switch (actionName) {
        case "edit":
          {
            this.setData({ isEditMode: true });
            this.openModal();
            this.getEquipmentData(this.selectedEquipmentId);
          }
          break;
        case "delete":
          {
            this.warningModalBtnText = "Delete";
            this.warningModalType = "delete";
            this.warningModalIsDangerButton = true;
            this.showWarningModal(true);
          }
          break;
        case "enable":
          {
            this.warningModalBtnText = "Enable";
            this.warningModalType = "enable";
            this.warningModalIsDangerButton = false;
            this.showWarningModal(true);
          }
          break;
        case "disable":
          {
            this.warningModalBtnText = "Disable";
            this.warningModalType = "disable";
            this.warningModalIsDangerButton = true;
            this.showWarningModal(true);
          }
          break;
      }
    },
    getTypes(typeNum) {
      let type;

      this.equipmentTypes.forEach((item) => {
        if (item.value === typeNum) {
          type = item.name;
        }
      });

      return type;
    },

    getDiscountsNames(data) {
      let discounts = "";
      if (data) {
        if (this.discountsList.length) {
          this.discountsList.map((item) => {
            data.map((discountId, index) => {
              if (item.id === discountId) {
                discounts +=
                  item.generalInfo.name + (data.length > 1 ? ", <br />" : "");
              }
            })
          });

          if (data.length === this.discountsList.length) {
            discounts = "All Discounts..."
          }
        } else {
          discounts = "-";
        }
      }

      if (!discounts)
        discounts = "-"
      return discounts;
    },
    getOnePiecePrice(prices) {
      if (prices && prices.length) {
        let onePiecePrice = prices[0].pieces.filter(item => item.piece === 1)
        return '$' + onePiecePrice[0].price
      }
    },

    closeImageModal() {
      this.setData({ selectedEquipmentId: "" });
      this.selectedImageSrc = null
      this.selectedImageId  = null
      this.isShowImage = false
    },

    async saveImageChanges(changedImgFile) {
      /* 
        getting selected equipment imagesList, selectedImageId, selectedImageSrc
        uploaded new changed image, then remove selected image,
        and then update equipment images LIst with new image
      */
      const selectedImageId = this.selectedImageId;
      const equipmentImagesList = this.dataTable.filter(item => item.id === this.selectedEquipmentId)[0]?.productImage?.imagesList

      await this.uploadImage(changedImgFile).then((newImage) => {
        this.selectedImageId = newImage.id
        if (equipmentImagesList.length && newImage.id) {
          equipmentImagesList[0] = newImage.id
        }
      })

      if (this.selectedImageId) {
        // await this.deleteImage(selectedImageId);
        await this.updateEquipment({
          productImage: {
            imagesList: equipmentImagesList
          }
        })
      }

      this.addClickFuncToImages()
      this.closeImageModal()
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";

.P-columns {
  padding-left: 10px;
}

.v-menu__content {
  max-width: 190px !important;
}
</style>

<style lang="scss">
  .equipment-image {
    width: 100% !important;
    height: 130px;
    object-fit: contain !important;
  }
</style>