<template>
  <div class="P-modal-components">
    <div class="P-modal-component-body">
      <div class="G-flex G-flex-wrap P-inputs">
        <div class="P-padding-50">
           <ott-input
            v-model="name"
            label="Name*"
            :error="showErrors && !name"
          />
        </div>
        <div class="P-padding-50">
          <ott-select
            v-model="type"
            :items="types"
            :error="showErrors && !type"
            label="Type*"
            selected-text="name"
          />
        </div>
        <div class="P-padding-50">
          <ott-select
            v-model="category"
            :error="$v.information.category.$error"
            :items="categoriesList"
            :multiple="false"
            :persistentHint="true"
            :clear="true"
            label="Category*"
            selected-text="name"
          />
        </div>

        <div class="P-padding-50">
          <ott-input :disabled="true" label="Product Stock" />
          <!-- v-model="productStock" -->
        </div>
        <div class="P-padding-100">
          <quill-editor
            v-model="description"
            :options="{ placeholder: 'Description' }"
            :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
          />
        </div>
        <div class="P-padding-20">
          <ott-checkbox v-model="enableForSale" label="Enable For Sale" />
        </div>
        <div class="P-padding-20">
          <ott-checkbox v-model="isService" label="Service" />
        </div>
      </div>

      <title-component title-text="Specifications" />

      <div class="G-flex G-flex-wrap P-inputs">
        <div class="P-padding-33">
          <ott-input v-model="manufacturer" label="Manufacturer" />
        </div>

        <div class="P-padding-33">
          <ott-input v-model="certification" label="Certification" />
        </div>

        <div class="P-padding-33">
          <ott-input v-model="sku" :error="showErrors && !sku" label="SKU*" />
        </div>

        <div class="P-padding-33">
          <ott-input v-model="condition" label="Condition" />
        </div>

        <div class="P-padding-33">
          <ott-input v-model="standart" label="Standart" />
        </div>

        <div class="P-padding-33">
          <ott-input v-model="os" label="OS" />
        </div>

        <div class="P-padding-16">
          <ott-select
            v-model="unitSize"
            :error="$v.information.unitSize.$error"
            :items="sizeList"
            selected-text="name"
            label="Unit*"
          />
        </div>

        <div class="P-padding-33 d-flex align-center">
          <ott-input
            v-model.number="height"
            :error="$v.information.height.$error"
            :min="0.01"
            type="number"
            label="H*"
          />
          <span class="mx-2 P-label">x</span>

          <ott-input
            v-model.number="width"
            :error="$v.information.width.$error"
            :min="0.01"
            type="number"
            label="W*"
          />
          <span class="mx-2 P-label">x</span>

          <ott-input
            v-model.number="length"
            :error="$v.information.length.$error"
            :min="0.01"
            type="number"
            label="L*"
          />
        </div>

        <div class="P-padding-16">
          <ott-select
            v-model.number="unitWeight"
            :min="0.01"
            :error="$v.information.unitWeight.$error"
            :items="weightList"
            selected-text="name"
            label="Unit*"
          />
        </div>

        <div class="P-padding-33">
          <ott-input
            v-model.number="productWeight"
            :error="$v.information.productWeight.$error"
            label="Product Weight *"
          />
        </div>
      </div>

      <div class="P-padding-20 px-0">
        <ott-button-loader
          v-if="isEditMode"
          @onClick="saveData"
          :is-loading="isLoading"
          :button-text="'Save'"
          className="primary-sm-btn P-login-btn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapState, mapMutations, mapActions } from "vuex";
import TitleComponent from "@/components/TitleComponent";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  validations: {
    information: {
      // name: {
      //   required,
      // },
      // type: {
      //   required,
      // },
      category: {
        required,
      },
      unitSize: {
        required,
      },
      height: {
        required,
      },
      width: {
        required,
      },
      length: {
        required,
      },
      unitWeight: {
        required,
      },
      productWeight: {
        required,
      },
    },
  },
  components: {
    TitleComponent,
    OttInput,
    OttSelect,
    OttCheckbox,
    OttButtonLoader,
  },
  data() {
    return {
      sizeList: [
        {
          name: "Cm",
          value: "cm",
        },
        {
          name: "In",
          value: "in",
        },
      ],

      weightList: [
        {
          name: "Kg",
          value: "kg",
        },
        {
          name: "Lb",
          value: "lb",
        },
      ],
      showErrors: false
    };
  },
  computed: {
    ...mapState({
      equipmentData: (state) => state.equipmentsModule.equipmentData,
      information: (state) => state.equipmentsModule.equipmentData.information,
      categoriesList: (state) => state.shippingClients.categoriesList,
      types: (state) => state.equipmentsModule.types,
      isEditMode: (state) => state.equipmentsModule.isEditMode,
      selectedEquipmentId: (state) =>
        state.equipmentsModule.selectedEquipmentId,
      isLoading: (state) => state.appModule.isLoading,
    }),

    name: {
      get() {
        return this.equipmentData.name;
      },
      set(value) {
        this.setEquipmentDataKeys({ key: 'name', value });
      },
    },
    type: {
      get() {
        return this.equipmentData.type;
      },
      set(value) {
        this.setEquipmentDataKeys({ key: 'type', value });
      },
    },
    description: {
      get() {
        return this.equipmentData.description;
      },
      set(value) {
        this.setEquipmentDataKeys({ key: 'description', value });
      },
    },
    enableForSale: {
      get() {
        return this.equipmentData.enableForSale;
      },
      set(value) {
        this.setEquipmentDataKeys({ key: 'enableForSale', value });
      },
    },
    isService: {
      get() {
        return this.equipmentData.isService;
      },
      set(value) {
        this.setEquipmentDataKeys({ key: 'isService', value });
      },
    },
    sku: {
      get() {
        return this.equipmentData.sku;
      },
      set(value) {
        this.setEquipmentDataKeys({ key: 'sku', value });
      },
    },
    category: {
      get() {
        return this.information.category;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "category",
          value
        });
      },
    },
    manufacturer: {
      get() {
        return this.information.manufacturer;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "manufacturer",
          value,
        });
      },
    },
    certification: {
      get() {
        return this.information.certification;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "certification",
          value,
        });
      },
    },
    condition: {
      get() {
        return this.information.condition;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "condition",
          value,
        });
      },
    },
    standart: {
      get() {
        return this.information.standart;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "standart",
          value,
        });
      },
    },
    os: {
      get() {
        return this.information.os;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "os",
          value,
        });
      },
    },
    unitSize: {
      get() {
        return this.information.unitSize;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "unitSize",
          value,
        });
      },
    },
    height: {
      get() {
        return this.information.height;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "height",
          value,
        });
      },
    },
    width: {
      get() {
        return this.information.width;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "width",
          value,
        });
      },
    },
    length: {
      get() {
        return this.information.length;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "length",
          value,
        });
      },
    },
    unitWeight: {
      get() {
        return this.information.unitWeight;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "unitWeight",
          value,
        });
      },
    },
    productWeight: {
      get() {
        return this.information.productWeight;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "information",
          secondLevel: "productWeight",
          value,
        });
      },
    },
  },
  methods: {
    ...mapMutations({
      setData: "equipmentsModule/setData",
      setEquipmentData: "equipmentsModule/setEquipmentData",
      setEquipmentDataKeys: "equipmentsModule/setEquipmentDataKeys"
    }),
    ...mapActions({
      updateEquipment: "equipmentsModule/updateEquipment",
    }),
    async saveData() {
      this.$v.information.$touch();
      this.showErrors = true;

      if (!this.$v.information.$error && this.name && this.type && this.sku) {
        if (this.isEditMode) {
          // create data for update equipment information tab
          let data = {};
          if (this.equipmentData.name) 
            data.name = [{ lang: "en", name: this.name }]
          
          if (this.description)
            data.description = this.description

          data.enableForSale = this.enableForSale
          
          if (this.isService)
            data.isService = this.isService

          if (this.type)
            data.type = this.type

          if (this.sku)
            data.sku = this.sku
            
          const information = {}
          for (const key in this.information) {
            if (this.information[key]) {
              information[key] = this.information[key]
            }
          }

          data.information = information

          await this.updateEquipment(data);
        } else {
          this.setData({ activeTab: 1 })
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-padding-16{
  width: 16.667%;
  padding: 10px;
  margin: 0 !important;
}

.P-label {
  font-size: $txt14;
  color: $neutral-color;
}
.P-inputs {
  margin: 0 -10px;
}
</style>