<template>
  <div>
    <input
      ref="file"
      type="file"
      accept="image/*"
      @change="fileChange"
      multiple
      hidden
    />
    <div
      class="drag-drop-zone w-100 d-flex align-center justify-center flex-column"
      :class="!imagesList.length ? 'bordered-zone' : 'dark-zone'"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <template v-if="!imagesList.length">
        <div class="d-flex justify-center flex-column align-center">
          <img src="@/assets/images/upload-photo.png" class="mb-3" width="75" />
          <p class="drag-drop-text mb-5">Drag & drop your images or</p>

          <ott-button-loader
            @onClick="upload()"
            button-text="Upload"
            class-name="primary-sm-btn"
          />
        </div>
      </template>
      <img
        v-else-if="imagesList.length && selectedImage"
        class="preview"
        :src="baseApiUrl + 'files/icon/' + selectedImage"
      />
      <img
        v-else-if="imagesList.length && !selectedImage"
        class="preview"
        :src="baseApiUrl + 'files/icon/' + imagesList[0]"
      />
    </div>

    <div
      v-if="imagesList.length"
      class="d-flex justify-space-between align-center middle-part my-4"
    >
      <div class="d-flex align-center mb-1">
        <!-- color picker part  -->
        <div class="position-relative">
          <div
            @click.stop="openColorPicker = !openColorPicker"
            :style="{
              backgroundColor:
                typeof colorCode === 'string' ? colorCode : colorCode?.hexa,
            }"
            class="middle-part__small-icon d-flex cursor-pointer me-3"
          >
            <img v-if="!colorCode" src="@/assets/images/empty.png" />
          </div>

          <transition name="fade">
            <div v-if="openColorPicker" class="middle-part__color-picker">
              <v-color-picker
                v-model="colorCode"
                elevation="10"
                dot-size="15"
                mode="hexa"
                show-swatches
                swatches-max-height="120px"
              />
            </div>
          </transition>
        </div>

        <!-- upload image part -->
        <div
          @click="upload()"
          class="middle-part__small-icon d-flex align-center justify-center"
        >
          <img src="@/assets/images/upload-icon.png" class="upload-img" />
        </div>
      </div>

      <ott-select
        v-model="colorFromList"
        :items="colorsSelectList"
        selected-text="name"
        label="Product Color"
      />
    </div>

    <hr class="mb-4" />

    <div class="d-flex align-center justify-center flex-wrap images">
      <div
        v-for="(image, index) of imagesList"
        :key="index"
        class="me-4 mb-4 images__img-cont cursor-pointer"
      >
        <span
          title="Delete Image"
          class="mdi mdi-close images__delete-icon position-absolute cursor-pointer"
          @click="deleteImg(image, index)"
        ></span>
        <img
          :src="baseApiUrl + 'files/icon/' + image"
          alt="Product Image"
        />
        <div
          @click="selectImage(index, image)"
          class="align-center d-flex justify-center images__edit"
        >
          <div @click="setData({ openImageEditor: true })">
            <span class="mdi mdi-pencil-outline me-1"> </span>
            <span>Edit</span>
          </div>
        </div>
      </div>
    </div>
    <image-editor
      v-if="imagesList.length && openImageEditor"
      @save="saveImageChanges($event)"
      @closeModal="setData({ openImageEditor: false })"
      :open-image-editor="openImageEditor"
      :show-edit-btn="true"
      :image-path="base64Img"
    />

    <div v-if="imagesList.length" class="w-max-content">
      <ott-button-loader
        v-if="isEditMode"
        :button-text="'Save'"
        :is-loading="isLoading"
        @onClick="saveImages()"
        className="primary-sm-btn P-login-btn"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import ImageEditor from "@/components/ImageEditor";

export default {
  components: {
    OttButtonLoader,
    OttSelect,
    ImageEditor,
  },
  computed: {
    ...mapState({
      selectedEquipmentId: (state) => state.equipmentsModule.selectedEquipmentId,
      productImage: (state) =>
        state.equipmentsModule.equipmentData.productImage,
      colorsList: (state) =>
        state.equipmentsModule.equipmentData.options.colorsList,
      colorsSelectList: (state) => state.equipmentsModule.colorsSelectList,
      openImageEditor: (state) => state.equipmentsModule.openImageEditor,
      isEditMode: (state) => state.equipmentsModule.isEditMode,
      isLoading: (state) => state.appModule.isLoading,
    }),

    baseApiUrl() {
      return process.env.VUE_APP_API_URL;
    },

    selectedImage: {
      get() {
        return this.productImage.selectedImage;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "productImage",
          secondLevel: "selectedImage",
          value,
        });
      },
    },
    imagesList: {
      get() {
        return this.productImage.imagesList;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "productImage",
          secondLevel: "imagesList",
          value,
        });
      },
    },
    colorCode: {
      get() {
        return this.productImage.colorCode;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "productImage",
          secondLevel: "colorCode",
          value,
        });
      },
    },

    colorFromList: {
      get() {
        return this.productImage.colorFromList;
      },
      set(value) {
        this.setEquipmentData({
          firstLevel: "productImage",
          secondLevel: "colorFromList",
          value,
        });
      },
    },
  },
  data() {
    return {
      isDragging: false,
      openColorPicker: false,
      selectImageIndex: null,
      selectImageId: null,
      imagesObj: {},
      base64Img: null,
    };
  },
  async mounted() {
    // await this.updateEquipment({ productImage: { imagesList: [] } });
    const colorsSelectList = [];

    if (this.colorsList.length) {
      this.colorsList.map((item) => {
        colorsSelectList.push({
          name: item.colorName,
          value: { ...item },
        });
      });
    }

    this.setData({ colorsSelectList });
  },
  methods: {
    ...mapActions({
      updateEquipment: "equipmentsModule/updateEquipment",
      getEquipmentData: "equipmentsModule/getEquipmentData",
      uploadImage: "fileUpload/uploadImage",
      deleteImage: "fileUpload/deleteImage",
    }),

    ...mapMutations({
      setData: "equipmentsModule/setData",
      setEquipmentData: "equipmentsModule/setEquipmentData",
      spliceProductImage: "equipmentsModule/spliceProductImage",
    }),

    upload() {
      this.$refs.file.click();
    },

    async fileChange(e, isDrop = false) {
      // get selected images
      this.imagesObj = isDrop ? e.dataTransfer.files : e.target.files;
      const imagesList = [];

      for (const key in this.imagesObj) {
        if (key !== "length" && key !== "item") {
          imagesList.push(this.imagesObj[key]);
        }
      }

      if (imagesList.length) {
        imagesList.map(async (image) => {
          // save images in server and get ids
          await this.uploadImage(image).then((img) => {
            this.imagesList.push(img.id);
          });
        });
      }
    },

    // Convert Image to base64
    convertBase64(image) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(image);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },

    /**
     * convert To blob file
     * **/
    b64toFile(base64) {
      let arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], "equipment-img-" + this.getRandomNumber()+'.jpg', {
        type: mime,
      });
    },

    async imageUrlToBlob(imageUrl) {
      return fetch(imageUrl)
        .then(response => response.blob());
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.fileChange(e, true);
      this.isDragging = false;
    },

    async selectImage(index, image) {
      // get blob file from imageUrl then get base64 from blob file for image-editor
      const imageUrlToBlob = await this.imageUrlToBlob(this.baseApiUrl + 'files/icon/' + image)
      this.base64Img = await this.convertBase64(imageUrlToBlob)

      this.selectedImage = image;
      this.selectImageIndex = index;
    },

    async saveImageChanges(changedImg) {
      const changedImgFile = this.b64toFile(changedImg)
      const selectedImage = this.selectedImage;
      const selectImageIndex = this.selectImageIndex;
      await this.uploadImage(changedImgFile).then((newImage) => {
        this.selectedImage = newImage.id
        this.imagesList[this.selectImageIndex] = newImage.id;
      })
      // await this.deleteImage(selectedImage);
      await this.updateEquipment({
        productImage: {
          imagesList: this.imagesList
        }
      })
      await this.getEquipmentData(this.selectedEquipmentId)
    },

    async saveImages() {
      if (this.imagesList.length) {
        const imagesData = {
          imagesList: this.imagesList,
        };

        if (this.selectedImage) imagesData.selectedImage = this.selectedImage;
        if (this.colorCode) imagesData.colorCode = this.colorCode?.hexa;
        if (this.colorFromList) imagesData.colorFromList = this.colorFromList;

        await this.updateEquipment({ productImage: { ...imagesData } });
      }
    },

    getRandomNumber() {
      return Math.floor(Math.random() * (1000000000000000 - 1 + 1)) + 1;
    },

    async deleteImg(image, index) {
      await this.deleteImage(image);
      this.spliceProductImage(index);
      await this.updateEquipment({
        productImage: {
          imagesList: this.imagesList
        }
      })
      await this.getEquipmentData(this.selectedEquipmentId)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.drag-drop-zone {
  height: 400px;
  border-radius: 5px;
  overflow: hidden;

  & .preview {
    height: 100%;
    border: 2px solid $primary-color;
    border-radius: 5px;
    width: 100%;
    object-fit: contain;
  }
  & .icon {
    width: 25px;
    height: 20px;
  }
  & .sub-text {
    font-size: 12px;
  }

  & .drag-drop-text {
    font-weight: bold;
    color: $neutral-color;
    font-size: 17px;
  }
}

.middle-part {
  &__small-icon {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid $neutral-color;

    & img {
      width: 100%;
    }

    & .upload-img {
      width: 70%;
      object-fit: contain;
    }
  }

  &__color-picker {
    position: absolute;
    z-index: 9;
    left: 0;
    bottom: 35px;
  }
}

hr {
  border: unset;
  border-top: 1px solid rgba(165, 170, 198, 0.45);
}

.images {
  &__img-cont {
    width: 110px;
    height: 100px;
    border: 1px solid grey;
    border-radius: 3px;
    position: relative;

    &:hover {
      & .images__edit {
        opacity: 1;
        background: rgb(0, 0, 0, 0.5);
      }

      & .images__delete-icon {
        opacity: 1;
      }
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__edit {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: $txt14;
    color: $white;
    font-weight: bold;
    opacity: 0;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__delete-icon {
    top: 6px;
    right: 3px;
    color: red;
    line-height: 10px;
    z-index: 99;
    opacity: 0;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

::v-deep {
  .v-text-field__details,
  .v-messages {
    display: none;
  }
}

.bordered-zone {
  border: 1.5px dashed $neutral-color;
}

.dark-zone {
  background-color: #5f666c;
}
</style>
